import { useMemo } from "react"
import { RigidBody, CuboidCollider } from "@react-three/rapier"
import { StartBlock, EndBlock, TrapBlock, WallBlock } from "../level/Blocks"

const Bounds = ({ length = 1 }) => {
  return (
    <RigidBody type="fixed" restitution={0.2} friction={0}>
      {/* Left */}
      <WallBlock
        position={[-2.15, 1 - 0.2, -(length * 2) + 2]}
        scale={[0.3, 2, 4 * length]}
        length={length}
      />
      {/* Right */}
      <WallBlock
        position={[2.15, 1 - 0.2, -(length * 2) + 2]}
        scale={[0.3, 2, 4 * length]}
        length={length}
      />
      {/* Back */}
      <WallBlock
        position={[0, 1 - 0.2, -(length * 4) + 2 - 0.15]}
        scale={[4 + 0.6, 2, 0.3]}
        length={length}
      />
      {/* Collider floor */}
      <CuboidCollider
        args={[2, 0.1, 2 * length]}
        position={[0, -0.1, -(length * 2) + 2]}
        restitution={0.2}
        friction={1}
      />
    </RigidBody>
  )
}

export default function Level({
  trapCount = 5,
  trapSeed = 0,
  types = ["spinner", "limbo", "glider"],
}) {
  const blocks = useMemo(() => {
    const memoBlocks = []
    for (let i = 0; i < trapCount; i++) {
      const randomType = types[Math.floor(Math.random() * types.length)]
      memoBlocks.push(randomType)
    }
    return memoBlocks
  }, [trapCount, trapSeed, types])

  return (
    <>
      <StartBlock position={[0, 0, 0]} />

      {blocks.map((block, index) => (
        <TrapBlock
          key={index}
          type={block}
          position={[0, 0, (index + 1) * -4]}
        />
      ))}

      <EndBlock position={[0, 0, -(trapCount + 1) * 4]} />

      <Bounds length={trapCount + 2} />
    </>
  )
}
