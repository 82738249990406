import * as THREE from "three"
import { useGLTF } from "@react-three/drei"
import { useState } from "react"

export function Award() {
  const models = useGLTF("./models/models.gltf")
  const awardTypes = [
    // "Marker",
    // "Owl",
    "AR_phone",
    "basic_shapes",
    "Camera",
    "Laptop",
    "VR",
    "360_video",
  ]
  const [randomAward] = useState(
    awardTypes[Math.floor(Math.random() * awardTypes.length)]
  )
  const randomAwardModel = models.scene.children.find(
    (model) => model.name === randomAward
  )

  if (randomAwardModel.children && randomAwardModel.children.length) {
    randomAwardModel.children.forEach((child) => {
      if (child instanceof THREE.Mesh) child.castShadow = true
    })
  } else if (randomAwardModel instanceof THREE.Mesh) {
    randomAwardModel.castShadow = true
  }

  return <primitive object={randomAwardModel} scale={0.75} />
}
