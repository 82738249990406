import "./style.css"
import { Perf } from "r3f-perf"
import ReactDOM from "react-dom/client"
import { Canvas } from "@react-three/fiber"
import { KeyboardControls } from "@react-three/drei"
import Experience from "./Experience.jsx"
import Interface from "./interface/Interface.jsx"

const root = ReactDOM.createRoot(document.querySelector("#root"))
const keyMapping = [
  { name: "forward", keys: ["ArrowUp", "KeyW"] },
  { name: "backward", keys: ["ArrowDown", "KeyS"] },
  { name: "leftward", keys: ["ArrowLeft", "KeyA"] },
  { name: "rightward", keys: ["ArrowRight", "KeyD"] },
  { name: "jump", keys: ["Space"] },
]

root.render(
  <>
    <KeyboardControls map={keyMapping}>
      <Interface />
      <Canvas
        shadows
        camera={{
          fov: 45,
          near: 0.1,
          far: 200,
          position: [2.5, 4, 6],
        }}
      >
        <Perf position="top-left" />
        <Experience />
        <color args={["#1D1D1F"]} attach="background" />
      </Canvas>
    </KeyboardControls>
  </>
)
