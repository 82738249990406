import { create } from "zustand"
import { subscribeWithSelector } from "zustand/middleware"

export default create(
  subscribeWithSelector((set) => {
    return {
      // General
      trapCount: Math.floor(Math.random() * 10) + 1,
      trapSeed: 0,
      startTime: 0,
      endTime: 0,

      // Phases
      phase: "ready",
      startGame: () => {
        set((state) => {
          if (state.phase === "ready")
            return { phase: "playing", startTime: Date.now() }
          return {}
        })
      },
      restartGame: () => {
        set((state) => {
          if (state.phase === "playing" || state.phase === "finished")
            // return { phase: "ready", trapSeed: Math.random() }
            return { phase: "ready" }
          return {}
        })
      },
      finishGame: () => {
        set((state) => {
          if (state.phase === "playing")
            return { phase: "finished", endTime: Date.now() }
          return {}
        })
      },
    }
  })
)
