import { useKeyboardControls } from "@react-three/drei"
import { addEffect } from "@react-three/fiber"
import { useEffect, useRef } from "react"
import useGame from "../store/useGame"

export default function Interface() {
  const restartGame = useGame((state) => state.restartGame)
  const phase = useGame((state) => state.phase)

  const stopwatch = useRef()

  const forward = useKeyboardControls((state) => state.forward)
  const rightward = useKeyboardControls((state) => state.rightward)
  const backward = useKeyboardControls((state) => state.backward)
  const leftward = useKeyboardControls((state) => state.leftward)
  const jump = useKeyboardControls((state) => state.jump)

  const clickSound = new Audio("./tick.wav")
  clickSound.volume = 0.5

  const positionCursor = ({ clientX, clientY }) => {
    const cursor = document.querySelector(".cursor")
    cursor.style.transform = `translate3d(${clientX}px, ${clientY}px, 0)`
  }

  const animateCursorDown = () => {
    clickSound.currentTime = 0.05
    clickSound.play()

    document.querySelector(".cursor #default").style.opacity = 0
    document.querySelector(".cursor #down").style.opacity = 1
  }

  const animateCursorUp = () => {
    document.querySelector(".cursor #down").style.opacity = 0
    document.querySelector(".cursor #default").style.opacity = 1
  }

  useEffect(() => {
    const unsubscribeEffect = addEffect(() => {
      const state = useGame.getState()
      let elapsedTime = 0

      if (state.phase === "playing") elapsedTime = Date.now() - state.startTime
      else if (state.phase === "finished") elapsedTime = state.endTime - state.startTime

      elapsedTime /= 1000
      elapsedTime = elapsedTime.toFixed(2)
      if (stopwatch.current) stopwatch.current.textContent = elapsedTime
    })

    window.addEventListener("mousemove", positionCursor)
    window.addEventListener("mousedown", animateCursorDown)
    window.addEventListener("mouseup", animateCursorUp)

    return () => {
      unsubscribeEffect()
      window.removeEventListener("mousemove", positionCursor)
      window.removeEventListener("mousedown", animateCursorDown)
      window.removeEventListener("mouseup", animateCursorUp)
    }
  }, [])

  return (
    <div className="interface">
      {/* Cursor */}
      <div className="cursor">
        <img src="./cursor.png" alt="Game cursor" id="default" />
        <img src="./cursor-down.png" alt="Game cursor" id="down" />
      </div>

      {/* Timer */}
      <div className="stop-watch" ref={stopwatch}>
        0.00
      </div>

      {/* Restart */}
      {phase === "finished" && (
        <div className="restart" onClick={restartGame}>
          Restart
        </div>
      )}

      {/* Controls */}
      <div className="controls">
        <div className="raw">
          <div className={`key ${forward ? "active" : ""}`}></div>
        </div>
        <div className="raw">
          <div className={`key ${leftward ? "active" : ""}`}></div>
          <div className={`key ${backward ? "active" : ""}`}></div>
          <div className={`key ${rightward ? "active" : ""}`}></div>
        </div>
        <div className="raw">
          <div className={`key large ${jump ? "active" : ""}`}></div>
        </div>
      </div>
    </div>
  )
}
