import * as THREE from "three"
import { useGLTF, useKeyboardControls } from "@react-three/drei"
import { RigidBody, useRapier, BallCollider } from "@react-three/rapier"
import { useFrame } from "@react-three/fiber"
import { useEffect, useRef, useState } from "react"
import useGame from "../store/useGame"

export default function Player() {
  const startGame = useGame((state) => state.startGame)
  const restartGame = useGame((state) => state.restartGame)

  const [subscribeKeys, getKeys] = useKeyboardControls()
  const { rapier, world } = useRapier()

  const [lerpedCameraPosition] = useState(() => new THREE.Vector3(50, 50, 50))
  const [lerpedCameraTarget] = useState(() => new THREE.Vector3())

  const jumpSound = new Audio("./jump.wav")
  jumpSound.volume = 0.5
  const player = useRef()
  const models = useGLTF("./models/models.gltf")
  const playerModel = models.scene.children.find(
    (model) => model.name === "Owl"
  )

  if (playerModel && playerModel.children) {
    if (playerModel.children.length) {
      playerModel.children.forEach((child) => {
        if (child instanceof THREE.Mesh) child.castShadow = true
      })
    }
  }

  const playerJump = () => {
    if (player && player.current) {
      const origin = player.current.translation()
      origin.y -= 0.5

      const direction = { x: 0, y: -1, z: 0 }
      const ray = new rapier.Ray(origin, direction)
      const hit = world.castRay(ray, 10, true)

      if (hit.toi < 0.1) {
        player.current.applyImpulse({ x: 0, y: 1, z: 0 })
        jumpSound.currentTime = 0
        jumpSound.play()
      }
    }
  }

  const reset = () => {
    player.current.setTranslation({ x: 0, y: 1, z: 0 })
    // player.current.setRotation({ x: 0, y: 0, z: 0 })
    player.current.setLinvel({ x: 0, y: 0, z: 0 })
    player.current.setAngvel({ x: 0, y: 0, z: 0 })
  }

  useEffect(() => {
    const unsubscribeReset = useGame.subscribe(
      (state) => state.phase,
      (phase) => {
        console.log("# Game phase:", phase)
        if (phase === "ready") reset()
      }
    )

    const unsubscribeJump = subscribeKeys(
      (state) => state.jump,
      (value) => {
        if (value) playerJump()
      }
    )

    const unsubscribeAny = subscribeKeys(() => startGame())

    return () => {
      unsubscribeReset()
      unsubscribeJump()
      unsubscribeAny()
    }
  }, [])

  useFrame((state, delta) => {
    // Controls
    const { forward, rightward, backward, leftward } = getKeys()

    const impluse = { x: 0, y: 0, z: 0 }
    const torque = { x: 0, y: 0, z: 0 }

    const impluseStrength = 0.8 * delta
    const torqueStrength = 0.4 * delta

    if (forward) {
      impluse.z -= impluseStrength
      torque.x -= torqueStrength
    }

    if (rightward) {
      impluse.x += impluseStrength
      torque.z -= torqueStrength
    }

    if (backward) {
      impluse.z += impluseStrength
      torque.x += torqueStrength
    }

    if (leftward) {
      impluse.x -= impluseStrength
      torque.z == torqueStrength
    }

    player?.current?.applyImpulse(impluse)
    player?.current?.applyTorqueImpulse(torque)

    // Camera
    const playerPosition = player?.current?.translation()
    const cameraPosition = new THREE.Vector3()
    cameraPosition.copy(playerPosition)
    cameraPosition.z += 3.5
    cameraPosition.y += 1

    const cameraTarget = new THREE.Vector3()
    cameraTarget.copy(playerPosition)
    cameraTarget.y += 0.3

    // Lerping camera
    lerpedCameraPosition.lerp(cameraPosition, 5 * delta)
    lerpedCameraTarget.lerp(cameraTarget, 5 * delta)

    state.camera.position.copy(lerpedCameraPosition)
    state.camera.lookAt(lerpedCameraTarget)

    // Phases
    if (playerPosition.y < -4) restartGame()
  })

  return (
    <RigidBody
      ref={player}
      canSleep={false}
      position={[0, 0.5, 0]}
      restitution={0.2}
      friction={1}
      colliders={false}
      linearDamping={0.5}
      angularDamping={0.5}
    >
      <BallCollider args={[0.4]} />
      <primitive
        object={playerModel}
        scale={0.5}
        rotation-y={Math.PI}
        position-z={-0.05}
        position-y={-0.05}
      />
    </RigidBody>
  )
}
