import * as THREE from "three"
import { useState, useRef } from "react"
import { useFrame } from "@react-three/fiber"
import { RigidBody } from "@react-three/rapier"

export function Spinner({ geometry, material }) {
  const [direction] = useState(() => (Math.random() < 0.5 ? -1 : 1))
  const [speed] = useState(() => Math.random() + 0.5)
  const trap = useRef(null)

  useFrame((state) => {
    const time = state.clock.getElapsedTime()
    const rotation = new THREE.Quaternion()
    rotation.setFromEuler(new THREE.Euler(0, time * speed * direction, 0))
    if (trap?.current) trap?.current.setNextKinematicRotation(rotation)
  })

  return (
    <RigidBody
      type="kinematicPosition"
      position={[0, 0.3, 0]}
      restitution={0.2}
      friction={0}
      ref={trap}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={geometry}
        material={material}
        scale={[3.5, 0.3, 0.3]}
      />
    </RigidBody>
  )
}

export function Limbo({ geometry, material, parentPosition }) {
  const [timeOffset] = useState(() => Math.random() * Math.PI * 2)
  const trap = useRef(null)

  useFrame((state) => {
    const time = state.clock.getElapsedTime()
    const y = Math.sin(time + timeOffset) + 1.15
    if (trap?.current) {
      trap?.current.setNextKinematicTranslation({
        x: parentPosition[0],
        y: parentPosition[1] + y,
        z: parentPosition[2],
      })
    }
  })

  return (
    <RigidBody
      type="kinematicPosition"
      position={[0, 0.3, 0]}
      restitution={0.2}
      friction={0}
      ref={trap}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={geometry}
        material={material}
        scale={[3.5, 0.3, 0.3]}
      />
    </RigidBody>
  )
}

export function Glider({ geometry, material, parentPosition }) {
  const [timeOffset] = useState(() => Math.random() * Math.PI * 2)
  const trap = useRef(null)

  useFrame((state) => {
    const time = state.clock.getElapsedTime()
    const x = Math.sin(time + timeOffset) * 1.25
    if (trap?.current) {
      trap?.current.setNextKinematicTranslation({
        x: parentPosition[0] + x,
        y: parentPosition[1] + 0.85,
        z: parentPosition[2],
      })
    }
  })

  return (
    <RigidBody
      type="kinematicPosition"
      position={[0, 0.3, 0]}
      restitution={0.2}
      friction={0}
      ref={trap}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={geometry}
        material={material}
        scale={[1.5, 1.5, 0.3]}
      />
    </RigidBody>
  )
}
