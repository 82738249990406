import { RigidBody } from "@react-three/rapier"
import * as THREE from "three"
import { Award } from "./Award"
import { Float, Text } from "@react-three/drei"
import { Spinner, Limbo, Glider } from "./Traps"
import useGame from "../store/useGame.jsx"

const blockGeometry = new THREE.BoxGeometry(1, 1, 1)
const mainBlockMaterial = new THREE.MeshStandardMaterial({ color: "#FFD074" })
const trapBlockMaterial = new THREE.MeshStandardMaterial({ color: "#1D1D1F" })
const trapItemMaterial = new THREE.MeshStandardMaterial({ color: "#17F1D1" })
const wallMaterial = new THREE.MeshStandardMaterial({ color: "#1D1D1F" })
// black #1D1D1F
// white #FAFAF6
// purple #A374FF
// turquoise #17F1D1
// yellow #FFD074
const blockSizing = {
  w: 4,
  h: 0.2,
  l: 4,
}

export const StartBlock = ({ position = [0, 0, 0] }) => {
  return (
    <group position={position}>
      {/* <Float floatIntensity={0.25} rotationIntensity={0.25}>
        <Text
          font="./bebas-neue.woff"
          scale={0.5}
          maxWidth={0.25}
          lineHeight={0.75}
          textAlign="right"
          position={[0.75, 0.65, 0]}
          rotation-y={-0.25}
        >
          Poppr Game
          <meshBasicMaterial toneMapped={false} />
        </Text>
      </Float> */}
      <mesh
        receiveShadow
        geometry={blockGeometry}
        material={mainBlockMaterial}
        position={[0, blockSizing.h * 0.5 * -1, 0]}
        scale={[blockSizing.w, blockSizing.h, blockSizing.l]}
      />
    </group>
  )
}

export const EndBlock = ({ position = [0, 0, 0] }) => {
  const finishGame = useGame((state) => state.finishGame)
  const winSound = new Audio("./pickup.wav")
  winSound.volume = 0.5

  const collisionEnter = () => {
    // resetGame()
    finishGame()
    winSound.currentTime = 0
    winSound.play()
  }

  return (
    <group position={position}>
      <Float speed={2} rotationIntensity={1.5} floatingRange={[-0.15, 0.15]}>
        <RigidBody
          type="fixed"
          position={[0, 1, 0]}
          colliders="cuboid"
          restitution={0.2}
          friction={0}
          onCollisionEnter={collisionEnter}
        >
          <Award />
        </RigidBody>
      </Float>
      <mesh
        receiveShadow
        geometry={blockGeometry}
        material={mainBlockMaterial}
        position={[0, blockSizing.h * 0.25 * -1, 0]}
        scale={[blockSizing.w, blockSizing.h + 0.1, blockSizing.l]}
      />
    </group>
  )
}

export const TrapBlock = ({ position = [0, 0, 0], type = null }) => {
  const trapTypes = ["spinner", "limbo", "glider"]
  if (!type) type = trapTypes[Math.floor(Math.random() * trapTypes.length)]

  const renderTrap = () => {
    switch (type) {
      case "spinner":
        return (
          <Spinner
            parentPosition={position}
            geometry={blockGeometry}
            material={trapItemMaterial}
          />
        )
      case "limbo":
        return (
          <Limbo
            parentPosition={position}
            geometry={blockGeometry}
            material={trapItemMaterial}
          />
        )
      case "glider":
        return (
          <Glider
            parentPosition={position}
            geometry={blockGeometry}
            material={trapItemMaterial}
          />
        )
    }
  }

  return (
    <group position={position}>
      {renderTrap()}
      <mesh
        receiveShadow
        geometry={blockGeometry}
        material={trapBlockMaterial}
        position={[0, blockSizing.h * 0.5 * -1, 0]}
        scale={[blockSizing.w, blockSizing.h, blockSizing.l]}
      />
    </group>
  )
}

export const WallBlock = ({
  position = [0, 0, 0],
  scale = [1, 1, 1],
  length = 1,
}) => {
  return (
    <group position={position}>
      <mesh
        receiveShadow
        castShadow
        geometry={blockGeometry}
        material={wallMaterial}
        scale={scale}
      />
    </group>
  )
}
