import { OrbitControls } from "@react-three/drei"
import { useControls } from "leva"
import { Physics } from "@react-three/rapier"
import Lights from "./level/Lights.jsx"
import Level from "./level/Level.jsx"
import Player from "./player/Player.jsx"
import useGame from "./store/useGame.jsx"

export default function Experience() {
  const trapCount = useGame((state) => state.trapCount)
  const trapSeed = useGame((state) => state.trapSeed)

  return (
    <>
      {/* <OrbitControls makeDefault /> */}
      <Lights />

      <Physics debug={false}>
        <Player />
        <Level trapCount={trapCount} trapSeed={trapSeed} />
      </Physics>
    </>
  )
}
